import React from "react";
import EmoticonOne from "../images/emoticon-one.png";
import EmoticonTwo from "../images/emoticon-two.png";

export const TitleSectionComponent = () => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column contain-section">
      <div className="d-flex align-items-center justify-content-center">
        <img src={EmoticonOne} alt="emoticon-one" className="emoticon-one" />
        <div className="contain-text">
          <p className="text-one">Plan de Beneficios</p>
        </div>
        <img src={EmoticonTwo} alt="emoticon-two" className="emoticon-two" />
      </div>
      <p className="text-two">para colaboradores</p>
    </div>
  );
};
