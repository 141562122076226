import React, { useEffect } from "react";
import CardComponent from "../../components/CardComponent";
import GoogleIcon from "./images/google-icon.svg";
import "./style/style.css";
import { useDispatch, useSelector } from "react-redux";
import { startGoogleLogin } from "../../redux/actions/auth";
import { useNavigate } from "react-router-dom";
import LogoAuta from "../../images/logo-auta.png";
import { HeaderComponent } from "../../components/HeaderComponent";
import { NavComponent } from "../../components/NavComponent";

const LoginPage = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const googleLogin = () => {
    dispatch(startGoogleLogin());
  };

  return (
    <>
      <NavComponent />
      <div className="d-flex align-items-center justify-content-center flex-column contain-login">
        <CardComponent title={"Iniciar Sesión"}>
          <div className="d-flex align-items-center justify-content-center">
            <button className="btn-google-login" onClick={googleLogin}>
              <img src={GoogleIcon} alt="google-icon" className="me-1" />
              Ingresar con Google
            </button>
          </div>
        </CardComponent>
      </div>
    </>
  );
};

export default LoginPage;
