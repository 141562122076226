import React from "react";
import { TitleSectionComponent } from "./TitleSectionComponent";
import "../styles/section.css";

export const SectionComponent = () => {
  return (
    <>
      <TitleSectionComponent />
    </>
  );
};
