import React, { useState } from "react";
import { ModalBrandComponent } from "./ModalBrandComponent";

export const CardBrandComponent = ({ logo, body, link, isBimbo }) => {
  const [showModalBrand, setShowModalBrand] = useState(false);

  return (
    <>
      <ModalBrandComponent
        isOpen={showModalBrand}
        onClose={() => setShowModalBrand(false)}
        logo={logo}
        body={body}
        link={link}
        isBimbo={isBimbo}
      />
      <div className="d-flex align-items-center justify-content-between flex-column card-benefits">
        <div
          style={{ height: "100%" }}
          className="d-flex align-items-center justify-content-center"
        >
          <img src={logo} alt="logo-brand" />
        </div>
        <button
          className="btn-show-benefits"
          onClick={() => setShowModalBrand(true)}
        >
          Ver beneficio
        </button>
      </div>
    </>
  );
};
