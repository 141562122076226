import React, { useState } from "react";
import { HeaderComponent } from "./HeaderComponent";
import { FooterComponent } from "./FooterComponent";
import "../styles/home.css";
import { SectionComponent } from "./SectionComponent";
import { CardBrandComponent } from "./CardBrandComponent";
import Bimbo from "../images/Bimbo.svg";
import Coderhouse from "../images/Coderhouse.svg";
import LuigiBosca from "../images/Luigi_Bosca.svg";
import On_Fit from "../images/On_Fit.svg";
import Samsung from "../images/Samsung.svg";
import UAI from "../images/UAI.svg";
import UB from "../images/UB.svg";
import UCES from "../images/UCES.svg";
import Unilver from "../images/Unilever.svg";
import US21 from "../images/US21.svg";
import BGH from "../images/BGH.svg";

export const HomeComponent = () => {
  return (
    <>
      <div className="contain-app">
        <HeaderComponent />
        <SectionComponent />
        <div className="container-cards-home">
          <div className="d-flex align-items-center justify-content-center flex-wrap container-cards">
            <CardBrandComponent
              link={"https://www.samshop.com/"}
              logo={Samsung}
              body={
                <>
                  <div className="divider-body">
                    <p className="text-body">
                      La plataforma de beneficios exclusivos de Samsung para
                      colaboradores. Recordá informar a tus colaboradores el
                      modo de acceso y su código de acceso.
                    </p>
                  </div>
                  <div className="divider-body">
                    <p className="title-body-benefits">Forma de acceso:</p>
                    <p className="text-body">
                      Ingresá a: https://www.samshop.com/ <br /> Ingresá con tu
                      mail corporativo: @auta.com.ar <br /> Colocá el código de
                      acceso SOLO para registrarte la primera vez. <br /> Código
                      de acceso: auta <br /> Aceptá los términos y condiciones.{" "}
                      <br /> Recibí el token en tu mail y colócalo en la página
                      y listo. <br /> ¡Ya podés disfrutar de tu beneficio!
                    </p>
                  </div>
                  <div className="divider-body">
                    <p className="title-body-benefits">
                      Por cualquier inconveniente en el acceso, contactarse con:
                    </p>
                    <p className="text-body">
                      Matias Daniel Contreras - mcontreras@e3ecommerce.com.ar{" "}
                      <br /> Massiel Quevedo - mquevedo@team-samsung.com
                    </p>
                  </div>
                  <div className="divider-body">
                    <p className="title-body-benefits">
                      A partir de ahora los colaboradores contarán con:
                    </p>
                    <p className="text-body">
                      · Descuentos exclusivos en productos seleccionados,
                      descuentos son superiores a cualquiera que puedas
                      encontrar en sitios oficiales de Samsung <br />· Precios
                      de fábrica. <br />· Garantía directa de Samsung. <br />·
                      Posibilidad de pagar con 2 tarjetas y en cuotas. <br />·
                      Plan canje de Samsung en celulares, tv y tablet. <br />·
                      Envíos gratuitos a todo el país.
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              link={"https://www.coderhouse.com/ar/"}
              logo={Coderhouse}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio</p>
                    <p className="text-body">
                      20% OFF adicional sobre el Plan CoderBeca en todos los
                      cursos y carreras.
                    </p>
                  </div>
                  <div className="divider-body">
                    <p className="title-body-benefits">Modalidad:</p>
                    <p className="text-body">
                      Sus colaboradores accederán al beneficio aplicando el
                      código único de descuento <br /> SANJORGE_CODER
                      directamente en el check out al momento de comprar un{" "}
                      <br /> curso/carrera.
                    </p>
                    <p className="text-body">
                      Es extesivo a familiares de los colaboradores.
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              link={"https://www.uces.edu.ar/"}
              logo={UCES}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">
                      Descuentos por convenios:
                    </p>
                    <p className="text-body">
                      10% de descuento en arancel en carreras de grado
                      (modalidad presencial). <br />
                      15% de descuento en arancel en carreras de posgrado.
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              link={"https://21.edu.ar/"}
              logo={US21}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio:</p>
                    <p className="text-body">
                      · 10% de Descuento a todo el personal que estudien bajo
                      las modalidades Distribuida, Distribuida Home o Presencial
                      Home.
                      <br />· 10% de Descuento a familiares directos y que
                      estudien bajo las modalidades Distribuida o Distribuida
                      Home. <br />· 10% de Descuento a hijos del personal que
                      estudien bajo modalidad Presencial
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              link={"https://www.ub.edu.ar/"}
              logo={UB}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio:</p>
                    <p className="text-body">
                      20% de descuento en carreras universitarias y
                      tecnicaturas, en cuotas <br /> mensales no en la matricula
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              link={"https://uai.edu.ar/"}
              logo={UAI}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio:</p>
                    <p className="text-body">
                      Bonificaciones sobre cuotas mensuales en la oferta
                      académica: para <br /> colaboradores, sus cónyuges,
                      padres, hijos y hermanos, <br /> los siguientes
                      beneficios:
                    </p>
                    <p className="text-body ms-2 mt-2">
                      · 20 % de bonificación en la cuota pura de todas las
                      carreras de grado, en <br /> todas las localizaciones y
                      turnos (con excepción de las detalladas que no <br />{" "}
                      aplican a ningún beneficio)
                      <br />· 20 % de bonificación en la cuota pura de todas las
                      carreras de posgrado en <br /> todas las localizaciones y
                      turnos (con excepción de las detalladas que no <br />{" "}
                      aplican a ningún beneficio) <br />· 30 % de bonificación
                      en la cuota pura de las siguientes carreras de grado:
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              link={"https://clubdebeneficios.com/"}
              logo={Unilver}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio:</p>
                    <p className="text-body">
                      Club de Beneficios (https://clubdebeneficios.com/) es una
                      web cerrada, donde <br /> tus colaboradores podrán
                      registrarse y acceder a:
                    </p>
                    <p className="text-body ms-2 mt-2">
                      · Descuentos hasta 50% en primeras marca: Dove, Skip, Ala,
                      Comfort, Knorr, Cif, Hellmann`s, Rexona, Axe, Knorr,{" "}
                      <br />
                      Milka, Oreo, Heineken, Sun, y muchas <br /> más!
                      <br />· + 300 Combos de producto: Soluciones a un click.
                      Conjunto de productos a <br /> un precio diferencial (ej:
                      combo para limpieza cocina, combo para cuidado <br /> de
                      ropa, combo para llenar tu alacena y muchos más.)
                      <br />· Promociones especiales todas las semanas: Por
                      ejemplo Flash Sale! <br /> · Descuentos de hasta el 60%
                      por tiempo limitado (“Solo por horas”, envíos <br />{" "}
                      gratis sorpresa, sorteos, regalos con tu compra… <br /> ·
                      Envíos a TODO el país: Puede ser gratis todos los días,{" "}
                      <br />
                      alcanzando un monto por zona geográfica:
                      https://clubdebeneficios.com/
                      <br />
                      medios-de-pago-y-envio
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              isBimbo={true}
              logo={Bimbo}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio:</p>
                    <p className="text-body">
                      Aprovechá la oportunidad de realizar pre-pedidos con
                      descuentos exclusivos. <br /> Los pedidos se gestionan
                      directamente con el distribuidor a través de <br />{" "}
                      WhatsApp (1152283380). La recepción, entrega y cobranza de
                      los productos <br /> están a cargo del mismo.
                    </p>
                    <p className="text-body mt-3">
                      Monto mínimo de compra de $25.000. <br />
                      Nombre de contacto  Jorge Albedro <br />
                      mail de contacto albedrojor@yahoo.com.ar
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              isBimbo={true}
              link={"https://wa.link/jn7fcl"}
              logo={On_Fit}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio:</p>
                    <p className="text-body">
                      On Fit Accedé al primer mes gratis y un 40% de descuento
                      en la cuota mensual <br /> en todas las sedes de ONFIT.
                      <br /> Aprovechá esta oportunidad y ponete en movimiento.
                    </p>
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <button
                        className="btn-show-benefits"
                        style={{ width: "210px" }}
                      >
                        <a
                          href={"https://wa.link/jn7fcl"}
                          target="_blank"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Consultá por WhatsApp
                        </a>
                      </button>
                    </div>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              link={"https://www.tienda.luigibosca.com/"}
              logo={LuigiBosca}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio:</p>
                    <p className="text-body">
                      Para obtener el beneficio, aplicá el código AUTA30 en el
                      carrito de pago al <br /> finalizar tu compra.
                    </p>
                  </div>
                </>
              }
            />
            <CardBrandComponent
              link={"https://afinidad.bgh.com.ar/"}
              logo={BGH}
              body={
                <>
                  <div className="divider-body">
                    <p className="title-body-benefits">Beneficio:</p>
                    <p className="text-body">
                      ¡Accedé a descuentos exclusivos para colaboradores! <br />
                      Ingresá en https://afinidad.bgh.com.ar, registrate con tu
                      correo corporativo y <br /> descubrí beneficios únicos en
                      toda la tienda. <br />
                      Explorá productos con promociones semanales, cuotas sin
                      interés y envíos <br /> gratis. <br /> Encontrá artículos
                      para el hogar de las mejores marcas: BGH, Samsung, VAIO,{" "}
                      <br /> Positivo, Likon, Sharp y Telefunken.
                    </p>
                  </div>
                </>
              }
            />
          </div>
        </div>
        <FooterComponent />
      </div>
    </>
  );
};
