import React from "react";
import LogoAuta from "../images/logo-auta.png";
import "../styles/footer.css";

export const FooterComponent = () => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column contain-footer">
      <img src={LogoAuta} alt="logo-auta" className="logo-auta" />
      <div className="contain-options-footer">
        <p className="text-footer">Copyright © 2023.Auta Tienda de Autos</p>
        <p className="text-footer">Términos y Condiciones</p>
        <p className="text-footer">Políticas de Privacidad</p>
      </div>
    </div>
  );
};
