import React from "react";
import "../styles/header.css";
import { NavComponent } from "./NavComponent";

export const HeaderComponent = () => {
  return (
    <div className="contain-header d-flex align-items-center justify-content-start flex-column">
      <NavComponent />
      <p className="text-header-two">Auta Club</p>
    </div>
  );
};
