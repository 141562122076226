import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { HomeComponent } from "../components/HomeComponent";
import LoginPage from "../modules/Auth/LoginPage";
import PrivateRoute from "./PrivateRoute";
import { auth } from "../firebase/config";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/auth";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Swal from "sweetalert2";

export default function AppRouter() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (
        user?.uid &&
        (user.email.includes("@auta") || user.email.includes("@kardur"))
      ) {
        dispatch(
          login(
            user.uid,
            user.displayName ? user.displayName : "",
            user.email,
            true
          )
        );
      } else if (user) {
        // Si el usuario no tiene un dominio autorizado, cerramos sesión
        signOut(auth);
        Swal.fire(
          "Acceso restringido.",
          "Solo los usuarios de Auta o Kardur pueden acceder.",
          "error"
        );
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomeComponent />
            </PrivateRoute>
          }
        />
        <Route path="login" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}
