import { Modal } from "react-bootstrap";
import CloseButtonModal from "../images/close-button-modal.svg";

export const ModalBrandComponent = ({
  isBimbo,
  isOpen,
  onClose,
  logo,
  body,
  link,
}) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered size="lg">
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-end">
          <img
            src={CloseButtonModal}
            alt="close-btn"
            style={{ cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <img src={logo} alt="logo-brand" className="mb-3" />
          {body ? <div>{body}</div> : <p>No hay información disponible</p>}
          {isBimbo ? null : (
            <button className="btn-show-benefits" style={{ width: "120px" }}>
              <a
                href={link}
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                Ir al sitio
              </a>
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
